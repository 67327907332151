<template>
   <base-layout page-title="About" page-default-back-link="/">
     <ion-content text-center>
        <div class="ion-text-center textA2">
            About
        </div>
         <ion-grid>
             <ion-row>
                 <ion-col offset-md="2" size-md="8" offset-lg="4" size-lg="4" offset-sm="0" size-sm="12">
                    <ion-card class="ion-text-center">
                        <div class="profile-content">

                            <ion-card-header class="box" style="color: white; font-weight: bold;">                    
                                <div class="textA">
                                   Designed in the ACS HALO lab by students
                                </div>
                                <div>Jordan Hasulube</div>
                                <div>Carter Henning</div>
                                <div>Ethan Odean</div>
                                <div>Noah Sommerville</div>
                            </ion-card-header>

                        </div>
                    </ion-card>     
                 </ion-col>
             </ion-row>
         </ion-grid>
        
        </ion-content> 
    </base-layout>               
</template>

<style>
.textA {
    font-size: 24px;
    color: rgb(245, 241, 241);
}
.textA2 {
    font-weight: bold;
    color: rgba(218, 165, 147, 0.7);
    font-size: 30px;
}
.box {
        border-radius: 3px;
        background-color:  rgba(218, 165, 147, 0.7);
        width: 60%; 
        margin-left: auto; 
        margin-right: auto;
    }


.profile-avatar {
    margin: auto;
    z-index: 1000;
}

.profile-content {
    padding-top: 50px;
    background: url(/assets/profileback.png) no-repeat top fixed, rgb(224, 223, 223);
    
    position: relative;
    height: 300px;
    width: 100%;
    
}

</style>

<script>
//import {IonButton, IonCard, IonCardSubtitle, IonAvatar, IonGrid, IonRow, IonCol} from '@ionic/vue';
import { shieldHalfOutline, cafeOutline } from 'ionicons/icons';
export default {
    components: {
        // IonButton,
        // IonCard,
        // IonCardSubtitle,
        // IonAvatar,
        // IonGrid,
        // IonRow,
        // IonCol,
    },
     data() {
        return {
            shieldHalfOutline,
            cafeOutline
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.loggedInUser;
        }
    },    
    methods: {
        logout() {
            this.$store.dispatch('signout');

            setTimeout(() => {
                this.$router.push('/');
                }, 1);
        }
    }
}
</script>